import React from 'react'
import Navbar from '../Navbar'
import Hero2 from './Hero2'
const Home2 = () => {
  return (
	<>
  <Navbar/>
  <Hero2/>
  
  
  </>
  )
}

export default Home2