import React from 'react'
import image2 from '../../assets/image2.svg'

const Image1 = () => {
  return (
	<div className='bg-yellow-100 mt-10 ml-8'>
		<img src={image2} alt="/" />
	</div>
  )
}

export default Image1