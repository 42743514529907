import React from 'react'
import home from '../assets/home.svg'

const Image = () => {
  return (
	<div className='bg-yellow-100 mt-10 ml-8'>
		<img src={home} alt="/" />
	</div>
  )
}

export default Image